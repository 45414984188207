<template>
  <div class="brand-index-tips">
    <div class="tips-wrap">
      <i
        @click="closeTipsFunc"
        class="fr el-icon-close"
      ></i>
      <h3>赢商品牌指数注释</h3>
      <div class="tips-text">
        <span></span>
        <p>品牌指数评价体系是由赢商大数据发布的 <strong>国内首个关于实体商业品牌商户的专业排行榜</strong>，涉及<strong>零售、餐饮、文体娱、儿童亲子、生活服务 </strong>5个大类，分成近<strong>40</strong>+不同类别进行测评排名； </p>
      </div>
      <div class="tips-text">
        <span></span>
        <p>旨在通过大数据量化分析品牌商 <strong>发展规模、市场运营情况、消费者评价、品牌势能</strong>等与品牌力高度相关的多个维度，从不同角度对品牌进行量化测评，并在此基础上，定期更新，产生动态权威的品牌排行榜，为商业地产从业者、投资人提供决策参考。</p>
      </div>
      <div class="index-table">
        <div class="table-header">
          <span style="width: 98px;">指数类型</span>
          <span style="width: 220px;">指数释义</span>
          <span style="width: 280px;">赋值方式</span>
          <span style="width: 260px;">赋值范围</span>
        </div>
        <div class="table-body">
          <div
            class="body-item"
            style="height: 106px;"
          >
            <div
              class="back-color"
              style="line-height: 106px;"
            >人气指数</div>
            <div class="back-color2">反映品牌受消费者关注的程度。指数越高，代表品牌在同品类中能吸引更多的受众关注。</div>
            <div class="back-color3">品牌消费者评价数据。根据顾客可信度、顾客评论质量、顾客评论数量、评论时间等各种维度进行综合加权计算，原始得分经过数据正态化处理，最终人气指数最高得分为10分。</div>
            <div class="back-color4">
              <span>24城5万方及以上购物中心开店量≥2的体验业态体验业态包含：一级业态：餐饮，文体娱，生活服务</span><br />
              <span>二级业态：儿童教育，儿童服务，儿童游乐</span>
            </div>
          </div>
          <div class="body-item">
            <div class="back-color">潜力指数</div>
            <div class="back-color2">反映近5年创立的新品牌发展潜力程度。指数越高，代表品牌在同品类的新品牌中，发展势头更高。</div>
            <div class="back-color3">从品牌市场表现（已开门店数量、新开店数量、新关店数量、门店增速）、消费者评价指数（大众点评点评量、星级评分）、品牌势能（已进驻购物中心租金水平以及入驻的楼层）三方面进行综合加权计算，原始得分经过数据正态处理，最终潜力指数最高得分为10分。</div>
            <div class="back-color4">
              <span>近5年创立的新品牌</span>
            </div>
          </div>
          <div class="body-item">
            <div class="back-color">综合实力指数</div>
            <div class="back-color2">反映品牌综合实力，指数越高，代表品牌在同品类中行业优势地位越大。同等条件下能给场所端带来更高形象、更多流量、更稳定经营及更明显的羊群效应。</div>
            <div class="back-color3">从品牌市场表现（已开门店数量、新开店数量、新关店数量、门店增速）、消费者评价指数（大众点评点评量、星级评分）、品牌势能（已进驻购物中心租金水平以及入驻的楼层）三方面进行综合加权计算，原始得分经过数据正态化处理，最终综合实力指数最高得分为10分。</div>
            <div class="back-color4">
              24城5万方及以上购物中心开店量≥3
            </div>
          </div>
          <div class="body-item">
            <div class="back-color">租金贡献指数</div>
            <div class="back-color2">反映品牌的租金贡献能力，指数越高，代表相同条件下（同品类，同面积），该品牌整体上能给商铺贡献更高租金或租金溢价。</div>
            <div class="back-color3">由品牌进驻的城市、商圈、购物中心以及购物中心的租金水平和楼层等维度构成，并设置不同的权重比例，原始得分经过数据正态化处理，最终租金贡献指数最高得分为10分。</div>
            <div class="back-color4">
              24城5万方及以上购物中心开店量≥2
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    document.body.style.overflowY = 'hidden'
  },
  methods: {
    closeTipsFunc () {
      // document.body.style.overflowY = 'auto'
      this.$emit('closeBrandIndex')
    }
  }
}
</script>

<style lang="stylus" scoped>
.brand-index-tips
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  width 100%
  height 100%
  background rgba(0, 0, 0, 0.5)
  z-index 11111
  .tips-wrap
    position fixed
    top 10%
    left 50%
    margin-left -450px
    width 880px
    min-height 570px
    padding 20px 18px 40px 18px
    background #fff
    z-index 11111
    border-radius 6px
    h3
      text-align center
      color #333
      font-size 28px
      margin-bottom 9px
    .el-icon-close
      cursor pointer
      color #333
    .tips-text
      position relative
      margin-top 14px
      p
        padding-left 13px
        color #666
        font-size 14px
        line-height 24px
      span
        display inline-block
        position absolute
        left 0px
        top 9px
        width 6px
        height 6px
        border-radius 50%
        background-color #FFA134
.index-table
  width 100%
  height 32px
  line-height 32px
  margin-top 30px
  .table-header
    display flex
    background-color #F5F5F5
    span
      display inline-block
      padding-left 10px
      font-size 12px
      color #333
      font-weight 400
  .table-body
    height 300px
    overflow-x auto
    .body-item
      display flex
      height 146px
      justify-content center
      align-items center
      background-color #fff
      margin-bottom 16px
      box-shadow 0px 6px 7px 0px rgba(0, 0, 0, 0.07)
      border 1px solid #F5F5F5
      div
        font-size 12px
        line-height 22px
        padding 0 10px
        color #333
      .back-color
        width 98px
        background-color #FFA134
        color #fff
        height 100%
        text-align center
        line-height 146px
      .back-color2
        width 220px
      .back-color3
        width 280px
      .back-color4
        width 260px
</style>
