<template>
  <div
    class="task-wrap is-scroll-x"
    v-loading.fullscreen.lock="Allloading"
    element-loading-text="加载中..."
  >
    <div class="task-mask">
      <div class="task-head">
        <i
          @click="hideTaskBrand"
          class="el-icon-close"
        ></i>
        <h3>招商任务发布 <span>请将招商目标品牌先添加到品牌落位方案中</span></h3>
      </div>
      <div class="task-content">
        <div
          v-if="taskEdit==6"
          class="add-investment-task-brand projectName"
        >
          <el-select
            :style="{width: inputNumber + 'px'}"
            popper-class="floorSelectPoper"
            @change="changeProject"
            v-model="selectProject"
          >
            <el-option
              v-for="item in projectList"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div
          v-else
          class="projectName"
        >{{projectName}}</div>
        <div class="task-left">
          <div class="left-head">
            <span>待招商品牌
              <!-- <el-tooltip class="item" effect="dark" placement="bottom">
                <span class="icon-mark"></span>
                <div slot="content">待招商品牌支持从首选品牌落位方案中选择</div>
              </el-tooltip> -->
            </span>
            <!-- <span class="fr number"> <i class="mustFill">{{brandList.length}}</i>/{{taskNumber}}项</span> -->
          </div>
          <div class="prog-wrap">
            <div class="input-wrap">
              <!-- <input
                class="search-input"
                placeholder="请输入待招商品牌关键字"
                v-model="filterText" /> -->
              <el-select
                class="whiteSelectDown"
                v-model="searchName"
                filterable
                remote
                popper-class='whiteSelectDown'
                style="width: 100%"
                placeholder="请输入关键词"
                @change="searchBrand"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in searchListBrand"
                  :key="item.gbid"
                  :label="item.brandName"
                  :value="item.gbid"
                >
                </el-option>
              </el-select>
              <i class="icon-font el-icon-search"></i>
            </div>
            <div class="task-list">
              <el-tree
                v-if="checkShow"
                :data="planList"
                show-checkbox
                node-key="id"
                :props="defaultProps"
                default-expand-all
                ref="treeBrand"
                :highlight-current="true"
                :default-checked-keys="checkedArr"
                :render-content="renderContent"
                @check="handleCheckChange"
              >
              </el-tree>
            </div>
          </div>
        </div>
        <div class="task-right">
          <div class="right-head">
            <span>已选择品牌</span>
            <span class="fr number"> <i class="mustFill">{{brandList.length}}</i> 项</span>
          </div>
          <div class="task-brand-list">
            <div class="select-brand">
              <ul>
                <li
                  v-for="(item, index) in brandList"
                  :key="index"
                >
                  <i
                    class="el-icon-close"
                    @click="deleteBrand(item)"
                  ></i>
                  <div class="brand-logo">
                    <!-- <span
                      v-if="item.trend === 1"
                      class="star-tag"
                    ></span> -->
                    <img
                      class="fl"
                      v-if="!item.brandLogo"
                      src="@/assets/images/workBench/notPic.jpg"
                      alt=""
                    >
                    <img
                      v-else
                      class="fl"
                      :src="item.brandLogo"
                      alt=""
                    >
                    <div class="brand-name">
                      <div>
                        <span @click="toDetails(item)">{{item.brandName}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <p
                    v-for="(list, index2) in item.floors"
                    :key="index2">拟落楼层：<span style="width: 35px;">{{list.floor}}</span> 拟落铺位：<span>{{list.berthNo}}</span> 首选落位：<span>{{list.isFirst === 1 ? '是': '否'}}</span> </p> -->
                  <template v-if="item.planBrands.length>0">
                    <p
                      v-for="child in item.planBrands"
                      :key="child.id"
                    >拟落楼宇楼层：<span style="width: 44px;">{{child.floor}}</span> 拟落铺位：<span class="ellipsis2">{{child.berthNo}}</span> 首选落位：<span style="width: 30px;">{{child.isFirst === 1 ? '是': '否'}}</span> </p>
                  </template>
                  <template v-else>
                    <p>拟落楼宇楼层：<span style="width: 44px;">--</span> 拟落铺位：<span>--</span> 首选落位：<span style="width: 30px;">--</span> </p>
                  </template>
                  <!-- <p>拟落楼层：<span>4F</span> 拟落铺位：<span>4097</span> 首选落位：<span>是</span> </p>
                  <p>拟落楼层：<span>4F</span> 拟落铺位：<span>4097</span> 首选落位：<span>是</span> </p> -->
                  <div class="select-input-wrap">
                    <span :class="fieldsSetting && fieldsSetting.followUser === 1?'indispensable':'dispensable'">跟进人员：</span>
                    <el-select
                      v-model="item.followName"
                      @change="changeFollow($event)"
                      popper-class="floorSelectPoper"
                      placeholder="请选择跟进人"
                    >
                      <el-option
                        v-for="item in followList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <br>
                    <span :class="fieldsSetting && fieldsSetting.planTime === 1?'indispensable':'dispensable'">计划时间：</span>
                    <el-date-picker
                      v-model="item.planDate"
                      type="daterange"
                      clearable
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @change="handlePanelDate"
                    >
                    </el-date-picker>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="release-btn">
        <span @click="hideTaskBrand">取消</span>
        <span
          class="curs"
          @click="releaseBrand()"
        >发布</span>
      </div>
      <div
        class="enterprise-table"
        id="mycanvas"
        ref="mycanvas"
        v-if="canvasShow"
      >
        <div class="edit-head">变更内容</div>
        <table>
          <thead>
            <tr>
              <td>序号</td>
              <td>变更类型</td>
              <td>招商品牌</td>
              <td>品牌业态</td>
              <td>落位楼宇楼层</td>
              <td>落位铺位</td>
              <td>是否首选品牌</td>
              <td>跟进人</td>
              <td>计划开始时间</td>
              <td>计划完成时间</td>
              <td>实际开始时间</td>
              <td>实际完成时间</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in brandList"
              :key="index"
            >
              <td>{{index + 1}}</td>
              <td>发布</td>
              <td>{{item.brandName}}</td>
              <td>{{item.industry?item.industry:'-'}}</td>
              <td v-if="item.planBrands && item.planBrands.length > 0">{{item.planBrands[0].floor || '-'}}</td>
              <td v-else>-</td>
              <td v-if="item.planBrands && item.planBrands.length > 0">{{item.planBrands[0].berthNo || '-'}}</td>
              <td v-else>-</td>
              <td v-if="item.planBrands && item.planBrands.length > 0">{{item.planBrands[0].isFirst ? '是' : '否'}}</td>
              <td v-else>-</td>
              <td>{{item.followId ? item.followId : '-'}}</td>
              <td>{{item.planDate && item.planDate.length ? item.planDate[0] : '-'}}</td>
              <td>{{item.planDate && item.planDate.length ? item.planDate[1] : '-'}}</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <approval-progress-popup
      v-if="isShowApproval"
      :taskId="taskId"
      :projectId="projectId"
      @hideApprovalEmit="hideApprovalEmit"
    ></approval-progress-popup>
  </div>
</template>

<script>
import api from '@/api'
import html2canvas from 'html2canvas'
import { b64toBlob } from '@/utils/base64.js'
import approvalProgressPopup from '@/views/workBench/components/approvalProgressPopup.vue'
import Bus from '@/utils/bus.js'
import dayjs from 'dayjs'
export default {
  components: {
    approvalProgressPopup
  },
  data () {
    return {
      setTimeOuter: '', // 处理tree checked防抖的setTimeout code
      setTimeOutArg: {
        data: {},
        checked: true
      },
      isIndeterminate: false,
      checkAll: true,
      taskAll: true,
      checkShow: true,
      checkList: [],
      checkTask: [],
      followVal: '',
      followList: [],
      planList: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      checkedArr: [],
      brandList: [],
      selectFollowList: [],
      filterText: '',
      searchName: '',
      searchListBrand: [],
      loading: false,
      Allloading: false,
      projectList: [],
      selectProject: '',
      inputNumber: 200,
      isShowApproval: false,
      taskId: 0,
      projectId: '',
      fieldsSetting: {},
      canvasShow: false,
      allBrandList: []
    }
  },
  props: {
    brandDetails: {
      type: Object
    },
    selectProjectId: {
      type: Number
    },
    taskEdit: {
      type: Number
    },
    planIdx: {
      type: Number
    },
    projectName: {
      type: String
    }
  },
  beforeDestroy () {
    window.removeEventListener('click', this.closeClick)
  },
  created () {
    if (this.taskEdit === 6) {
      this.getBerthProjectFunc()
    } else {
      this.projectId = this.selectProjectId
      // this.getBerthBrandList()
      this.getFollow()
    }
  },
  mounted () {
    window.addEventListener('click', this.closeClick)
    this.getFieldsSetting()
  },
  watch: {
    filterText (val) {
      this.$refs.treeBrand.filter(val)
    },
    projectId (newVal, oldVal) {
      this.projectId = newVal
      this.getBerthBrandList()
      this.getFollow()
    }
  },
  methods: {
    handlePanelDate (val) {
      this.brandList = this.brandList.map(item => {
        for (let index = 0; index < item.planDate.length; index++) {
          item.planDate[index] = dayjs(item.planDate[index]).format('YYYY-MM-DD')
        }
        return item
      })
    },
    getFieldsSetting () {
      const params = {
        projectId: this.selectProjectId
      }
      this.axios.post(api.getTaskOptionalFields, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.fieldsSetting = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    closeClick (e) {
      if (e.target.className === 'task-wrap') {
        this.hideTaskBrand()
      }
    },
    async initInBrandPage () {
      // 清除之前选中的
      this.checkedArr = []
      this.brandList = []
      try {
        await this.getBerthBrandList()
        const flag = await this.judgeBrandHasInTask()
        if (flag.data.code === 0 && !flag.data.data) {
          await this.remoteMethod(this.brandDetails.brandName)
          this.searchName = this.brandDetails.gbid
          this.searchBrand()
          this.getFollow()
        } else {
          this.$message({
            type: 'warning',
            message: '当前项目已经存在该品牌的招商任务'
          })
        }
      } catch (error) {
      }
    },
    judgeBrandHasInTask () {
      return this.axios.post(api.isExistBerthTask, { projectId: this.projectId, gbid: this.brandDetails.gbid })
    },
    hideTaskBrand () {
      this.$emit('hideTaskFunc')
    },
    getBerthBrandList () {
      return this.axios.post(api.getBerthTaskPlan, { projectId: this.projectId })
        .then((res) => {
          this.planList = res.data.data.map((item) => {
            const obj = {
              label: item.label,
              id: item.id,
              disabled: false,
              level: 1,
              children: item.children
            }
            obj.children = item.children.map((option) => {
              const obj = {
                label: option.label,
                id: option.id,
                disabled: false,
                level: 2,
                children: option.children
              }
              obj.children = option.children.map((list) => {
                let obj = {}
                obj = list
                obj.label = list.berthNo + ' ' + list.brandName + (list.isFirst === 1 ? ' ( 首选落位 )' : '')
                obj.value = list.brandId
                obj.floor = option.label
                obj.level = 3
                obj.id1 = item.id
                obj.id2 = list.berthNo
                obj.id = option.id + list.berthId + list.gbid
                obj.followName = ''
                obj.followId = ''
                obj.planDate = ''
                obj.planBrands = []
                obj.industry = list.industry
                obj.disabled = list.approvalStatus === '审批中' ? true : undefined
                return obj
              })
              return obj
            })
            return obj
          })
          this.allBrandList = []
          for (let i = 0; i < this.planList.length; i++) { // 从tree组件的data处理选中，维护已选择品牌
            const item = this.planList[i]
            if (item.children && item.children.length) {
              for (let j = 0; j < item.children.length; j++) {
                const item1 = item.children[j]
                if (item1.children && item1.children.length) {
                  for (let k = 0; k < item1.children.length; k++) {
                    const item2 = item1.children[k]
                    if (item2.disabled === undefined) {
                      this.allBrandList.push(item2)
                    }
                  }
                }
              }
            }
          }
          for (let i = 0; i < this.allBrandList.length; i++) {
            const currentObj = this.allBrandList[i]
            for (let j = 0; j < this.allBrandList.length; j++) {
              const item = this.allBrandList[j]
              if (
                i !== j &&
                currentObj.gbid === item.gbid
              ) {
                if ('others' in currentObj) {
                  currentObj.others.push({ id: item.id, id1: item.id1, id2: item.id2 })
                } else {
                  currentObj.others = []
                  currentObj.others.push({ id: item.id, id1: item.id1, id2: item.id2 })
                }
              }
            }
          }
          if (this.taskEdit === 1) {
            const data = {
              berthId: this.brandDetails.planBrands[this.planIdx].berthId,
              berthNo: this.brandDetails.planBrands[this.planIdx].berthNo,
              brandId: this.brandDetails.planBrands[this.planIdx].brandId,
              brandLogo: this.brandDetails.planBrands[this.planIdx].brand.brandLogo,
              brandName: this.brandDetails.planBrands[this.planIdx].brand.brandName,
              createTime: this.brandDetails.planBrands[this.planIdx].createTime,
              createUserId: this.brandDetails.planBrands[this.planIdx].createUserId,
              createUserName: this.brandDetails.planBrands[this.planIdx].createUserName,
              floor: this.brandDetails.planBrands[this.planIdx].floor,
              followId: '',
              followName: '',
              planDate: '',
              level: 3,
              others: [],
              industry: this.brandDetails.berth.industry,
              gbid: this.brandDetails.planBrands[this.planIdx].brand.gbid,
              id: this.brandDetails.planBrands[this.planIdx].floor + this.brandDetails.planBrands[this.planIdx].berthId + this.brandDetails.planBrands[this.planIdx].brand.gbid,
              isFirst: 1,
              label: this.brandDetails.planBrands[this.planIdx].berthNo + this.brandDetails.planBrands[this.planIdx].brand.brandName + (this.brandDetails.planBrands[this.planIdx].isFirst === 1 ? ' ( 首选落位 )' : ''),
              planId: this.brandDetails.planBrands[this.planIdx].planId,
              projectId: this.brandDetails.planBrands[this.planIdx].projectId,
              value: this.brandDetails.planBrands[this.planIdx].brandId,
              trend: this.brandDetails.planBrands[this.planIdx].brand.trend
            }
            for (let i = 0; i < this.allBrandList.length; i++) {
              const item = this.allBrandList[i]
              if (item.gbid === data.gbid && item.id !== data.id) {
                data.others.push({ id: item.id, id1: item.id1, id2: item.id2 })
              }
            }
            this.$nextTick(() => {
              this.$refs.treeBrand.setCheckedKeys([data.id]) // 当前已选中的id
              this.handleCheckChange(data)
            })
          }
        })
    },
    handleCheckChange (data, node) {
      // console.log(111, data, node)
      if (node && node.checkedKeys.length === 0) {
        const removeGbid = this.allBrandList.map((item) => {
          return item.gbid
        })
        this.brandList = this.brandList.filter((items) => {
          if (!removeGbid.includes(items.gbid)) return items
        })
      }
      let isChecked = false
      const list = this.$refs.treeBrand.getCheckedNodes()
      const selected = list.filter(item => item.gbid) // 最底层选中的
      const selectedIds = selected.map(item => {
        return item.gbid
      })
      let arr = []
      this.allBrandList.forEach(item => {
        if (selectedIds.indexOf(item.gbid) > -1) {
          arr.push(item)
        }
      })
      if (!data.children) { // 选择最底层的
        selected.forEach(item => {
          if (item.id === data.id) {
            isChecked = true
          }
        })
        if (!isChecked) {
          this.brandList = this.brandList.filter((items) => {
            if (!selectedIds.includes(items.gbid)) return items
          })
          if (selectedIds.indexOf(data.gbid) > -1) {
            arr = arr.filter(item => item.gbid !== data.gbid)
          }
        }
      } else { // 选择层级有children的
        if (data.level === 2) {
          const ids = data.children.map(item => item.id)
          selected.forEach(item => {
            if (ids.indexOf(item.id) > -1) {
              isChecked = true
            }
          })
          if (!isChecked) {
            let gbids = data.children.map(item => item.gbid)
            data.children.forEach(item => {
              if (selectedIds.indexOf(item.gbid) > -1) {
                arr = arr.filter(obj => obj.gbid !== item.gbid)
              }
            })
            gbids = [...new Set(gbids)]
            this.brandList = this.brandList.filter((items) => {
              if (!gbids.includes(items.gbid)) return items
            })
          }
        }
      }
      this.brandList = []
      this.$refs.treeBrand.setCheckedNodes(arr)
      const checkedList = this.$refs.treeBrand.getCheckedKeys()
      const removeList = []
      for (let i = 0; i < this.allBrandList.length; i++) {
        const item = this.allBrandList[i]
        if (checkedList.includes(item.id)) {
          item.planBrands = [{
            floor: item.floor,
            berthId: item.berthId,
            berthNo: item.berthNo,
            isFirst: item.isFirst
          }]
          this.brandList.push(item)
        } else {
          removeList.push(item.gbid)
        }
      }
      this.brandList.forEach(element => {
        if ('others' in element) {
          for (let i = 0; i < this.brandList.length; i++) {
            const item = this.brandList[i]
            if (element.id !== item.id && element.gbid === item.gbid) {
              element.planBrands.push(...item.planBrands)
              this.brandList.splice(i, 1)
            }
          }
        }
      })
    },
    renderContent (h, { node, data, store }) {
      return `
        <span class="custom-tree-node">
          <span>${node.label}</span>
          <span class="btn-name" on-click=${() => this.approval(data, node)}>${node.level === 3 && data.approvalStatus ? '审批中' : ''}</span>
        </span>`
    },
    approval (data, node) {
      this.taskId = data.taskId
      this.isShowApproval = true
    },
    hideApprovalEmit () {
      this.isShowApproval = false
    },
    deleteBrand (item) {
      this.brandList = this.brandList.filter((val) => {
        return item.gbid !== val.gbid
      })
      const checkedList = this.$refs.treeBrand.getCheckedKeys() // 当前已选中的id
      const arr = []
      for (const i of item.planBrands) {
        arr.push(i.floor + i.berthId + item.gbid)
      }
      const checkedArr = checkedList.filter(items => {
        if (!arr.includes(items)) return items
      })
      // checkedList = checkedList.filter(item1 => {
      //   return item1 !== item.id
      // })
      // checkedList = checkedList.filter(item1 => {
      //   return item1 !== item.id2
      // })
      // checkedList = checkedList.filter(item1 => {
      //   return item1 !== item.id1
      // })
      this.$refs.treeBrand.setCheckedKeys(checkedArr)
    },
    getFollow () {
      this.axios.post(api.getFollowList, { projectId: this.projectId, isShow: 1 })
        .then((res) => {
          this.followList = res.data.data.map((item) => {
            const obj = {
              value: item.userId,
              label: item.userName
            }
            return obj
          })
        })
    },
    changeFollow (val, index) {
      let arr = {}
      arr = this.followList.find((item) => {
        return item.value === val
      })
      this.selectFollowList.push(arr)
      this.brandList = this.brandList.map((item) => {
        let obj = {}
        obj = item
        if (val === item.followName) {
          obj.followId = arr.label
        }
        return obj
      })
    },
    releaseBrand () {
      const data = []
      // console.log('this.bradList', this.brandList)
      for (let i = 0; i < this.brandList.length; i++) {
        const obj = {}
        obj.brandId = this.brandList[i].brandId
        obj.followUserId = this.brandList[i].followName
        obj.followUserName = this.brandList[i].followId
        if (this.fieldsSetting && this.fieldsSetting.followUser === 1 && (this.brandList[i].followName === '')) {
          this.$message({
            type: 'warning',
            message: '跟进人员为必填选项，请选择跟进人员'
          })
          return false
        }
        if (this.fieldsSetting && this.fieldsSetting.planTime === 1 && (this.brandList[i].planDate === '' && this.brandList[i].planDate.length === 0)) {
          this.$message({
            type: 'warning',
            message: '计划时间为必填选项，请选择计划时间'
          })
          return false
        }
        if (this.brandList[i].planDate && this.brandList[i].planDate.length > 0) {
          obj.planStartTime = this.brandList[i].planDate[0]
          obj.planFinishTime = this.brandList[i].planDate[1]
        }
        obj.gbid = this.brandList[i].gbid
        obj.name = this.brandList[i].brandName
        obj.industry = this.brandList[i].industry
        obj.projectId = this.projectId
        if (this.brandList[i].planBrands && this.brandList[i].planBrands.length > 0) {
          obj.berthNo = this.brandList[i].planBrands[0].berthNo
          obj.floor = this.brandList[i].planBrands[0].floor
          obj.industry = this.brandList[i].planBrands[0].industry
          obj.isFirst = this.brandList[i].planBrands[0].isFirst
        }
        data.push(obj)
      }
      this.canvasShow = true
      if (data.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择品牌'
        })
        return false
      }
      this.Allloading = true
      this.isApproval().then((res) => {
        if (res.data.data === 1) {
          const params = {
            projectId: this.projectId,
            type: '新增'
          }
          this.axios.post(api.isGdwsApprovalChainIsNotRnabled, params)
            .then((res) => {
              if (res.data.data === 1) {
                this.saveImg('mycanvas').then((res) => {
                  const AddBerthTaskListDTO = {}
                  AddBerthTaskListDTO.addBerthTaskDTOs = data
                  AddBerthTaskListDTO.projectId = this.projectId
                  AddBerthTaskListDTO.url = res
                  this.axios.post(api.fabuBerthTaskChain, AddBerthTaskListDTO)
                    .then((res) => {
                      if (res.data.code === 0) {
                        this.$message({
                          type: 'success',
                          message: '提交成功，招商任务将在审批通过后发布! '
                        })
                        this.Allloading = false
                        this.$emit('hideTaskFunc', true)
                        Bus.$emit('refreshBus')
                      }
                      this.Allloading = false
                    })
                })
              } else {
                this.axios.post(api.fabuberthTask, { addBerthTaskDTOs: data })
                  .then((res) => {
                    if (res.data.code === 0) {
                      this.$message({
                        type: 'success',
                        message: '发布招商任务成功！'
                      })
                      this.Allloading = false
                      this.$emit('hideTaskFunc', true)
                      Bus.$emit('refreshBus')
                    }
                    this.Allloading = false
                  })
              }
            })
        } else {
          this.axios.post(api.fabuberthTask, { addBerthTaskDTOs: data })
            .then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: '发布招商任务成功！'
                })
                this.Allloading = false
                this.$emit('hideTaskFunc', true)
                Bus.$emit('refreshBus')
              }
              this.Allloading = false
            })
        }
      })
    },
    saveImg (val) {
      return new Promise((resolve) => {
        window.pageYoffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        // 先获取你要转换为img的dom节点
        var node = document.getElementById(val)// 传入的id名称
        // console.log("node", node);
        var width = node.offsetWidth // dom宽
        var height = node.offsetHeight // dom高
        var scale = 1.2 // 放大倍数 这个相当于清晰度 调大一点更清晰一点
        html2canvas(node, {
          width: width,
          heigth: height,
          backgroundColor: '#ffffff', // 背景颜色 为null是透明
          dpi: window.devicePixelRatio * 2, // 按屏幕像素比增加像素
          scale: scale,
          X: 0,
          Y: 0,
          scrollX: -3, // 如果左边多个白边 设置该偏移-3 或者更多
          scrollY: 0,
          useCORS: true, // 是否使用CORS从服务器加载图像 !!!
          allowTaint: true // 是否允许跨域图像污染画布  !!!
        }).then(canvas => {
          var url = canvas.toDataURL() // 这里上面不设值cors会报错
          const base64 = b64toBlob(url)
          const files = new window.File([base64], '111.png', { type: 'image/png' })
          var forms = new FormData()
          var configs = {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
          forms.append('file', files)
          this.axios.post(api.ChainPicture, forms, configs)
            .then((res) => {
              if (res.status === 200) {
                resolve(res.data.data)
              }
            })
        })
      })
    },
    // generateImage () {
    //   var canvas2 = document.createElement('canvas')
    //   const _canvas = document.querySelector('div')
    //   var w = parseInt(window.getComputedStyle(_canvas).width)
    //   var h = parseInt(window.getComputedStyle(_canvas).height)
    //   // 将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
    //   canvas2.width = w * 2
    //   canvas2.height = h * 2
    //   canvas2.style.width = w + 'px'
    //   canvas2.style.height = h + 'px'
    //   // 可以按照自己的需求，对context的参数修改,translate指的是偏移量
    //   //  var context = canvas.getContext("2d");
    //   //  context.translate(0,0);
    //   var context = canvas2.getContext('2d')
    //   context.scale(2, 2)
    //   html2canvas(document.querySelector('div'), { canvas: canvas2 }).then(function (canvas) {
    //     console.log(canvas)
    //     // canvas转换成url，然后利用a标签的download属性，直接下载，绕过上传服务器再下载
    //   })
    // },
    isApproval () {
      return new Promise((resolve) => {
        this.axios.post(api.approvalChainIsNotRnabled).then((res) => {
          if (res.status === 200) {
            resolve(res)
          }
        })
      })
    },
    toDetails (item) {
      const brandDetailsHref = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: item.brandId,
          source: 1,
          gbid: item.gbid,
          questionState: 0
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    },
    remoteMethod (query) {
      if (!query) return
      return this.axios.post(api.getSearchdeploy, {
        projectId: this.projectId,
        brandName: query
      })
        .then((res) => {
          this.brandLoad = false
          if (res.data.code === 0) {
            this.searchListBrand = res.data.data.map(item => {
              item.followName = ''
              item.followId = ''
              if (item.planBrands.length > 0) {
                item.planBrands = item.planBrands.map(child => {
                  child.id = child.floor + child.berthId + child.gbid
                  return child
                })
              }
              return item
            })
          }
        })
    },
    searchBrand () {
      const checkedList = []
      this.allBrandList.forEach(item => {
        if (item.gbid === this.searchName) {
          checkedList.push(item.id)
        }
      })
      this.$refs.treeBrand.setCheckedKeys(checkedList)
      const flag = this.brandList.some(list => {
        return list.gbid === this.searchName
      })
      if (!flag) {
        this.searchListBrand.forEach(item => {
          if (this.searchName === item.gbid) {
            this.brandList.push(item)
          }
        })
      }
      this.searchName = ''
    },
    getBerthProjectFunc () {
      return this.axios.post(api.getBerthProjectList)
        .then((res) => {
          this.projectList = res.data.data.map((item) => {
            return {
              label: item.projectName,
              value: item.id
            }
          })
          if (this.projectList.length > 0) {
            this.selectProject = this.projectList[0]
            this.projectId = this.selectProject.value
            this.initInBrandPage()
          }
        })
    },
    changeProject (item) {
      if (typeof (item) !== 'string') {
        // this.inputNumber = this.byteLength(item.label) * 11.5
        this.projectId = this.selectProject.value
        this.initInBrandPage()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.task-wrap
  position fixed
  left 0
  top 0
  bottom 0
  right 0
  background rgba(0, 0, 0, 0.5)
  z-index 1111
  overflow-y auto
  .task-mask
    position absolute
    left 50%
    margin-left -450px
    top 20px
    width 860px
    min-height 600px
    background #ffffff
    border-radius 6px
    padding 20px 20px
    .task-head
      .el-icon-close
        position absolute
        right 20px
        top 15px
        color #D9D9D9
        cursor pointer
      h3
        font-size 18px
        color #333
        font-weight bold
        span
          font-size 14px
          color #999
          font-weight 500
          margin-top 5px
.task-content
  margin-top 15px
  .task-left
    float left
    width 48%
    height 450px
    overflow-y auto
    // border-bottom 1px solid #ccc
    .left-head span
      color #333
      font-size 14px
      font-weight 500
    .number
      color #666
    .prog-wrap
      margin-top 10px
      height 460px
      border 1px solid rgba(238, 238, 238, 1)
      box-shadow 0px 5px 8px 0px rgba(50, 29, 6, 0.04)
      .input-wrap
        position relative
        box-shadow 0px 5px 8px 0px rgba(50, 29, 6, 0.04)
        input
          width calc(100% - 15px)
          height 40px
          border-bottom 1px solid #d9d9d9d9
          padding-left 15px
          font-size 14px
        .el-icon-search
          position absolute
          right 20px
          top 13px
          color #999
      .task-list
        margin-top 0px
  .task-right
    float right
    width 48%
    .right-head span
      color #333
      font-size 14px
      font-weight 500
    .task-brand-list
      background #ffffff
      margin-top 10px
      height 390px
      border 1px solid rgba(238, 238, 238, 1)
      box-shadow 0px 5px 8px 0px rgba(50, 29, 6, 0.04)
      padding 15px
      overflow-y auto
      .select-brand
        ul li
          position relative
          width 100%
          box-sizing border-box
          box-shadow 0px 9px 32px 0px rgba(50, 29, 6, 0.06)
          border 1px solid #fff
          background #fff
          margin-bottom 20px
          .select-input-wrap>>>
            margin-top 5px
            overflow hidden
            .el-select .el-input__inner
              margin 13px 0 0 0
              width 225px
            span
              font-size 14px
              margin-left 12px
            .el-date-editor--daterange.el-input__inner
              margin 13px 0 13px 13px
              width 225px
          .brand-logo
            // display flex
            // align-items center
            height 70px
            margin-bottom 5px
            .brand-name
              padding-top 10px
            img
              width 67px
              height 67px
              // margin-top 10px
              margin-left 10px
              object-fit cover
            span
              margin-left 10px
              font-size 14px
              color #333
              font-weight 500
              cursor pointer
          p
            color #999
            font-size 12px
            font-weight 500
            margin-left 5px
            margin-right 5px
            line-height 28px
            border-bottom 1px dashed rgba(217, 217, 217, 0.5)
            margin-top 5px
            margin-bottom 5px
            display flex
            align-items center
            &:last-child
              border none
            span
              display inline-block
              width 60px
              color #666
          .el-icon-close
            position absolute
            right 8px
            top 8px
            color #666
            font-size 16px
            cursor pointer
            &:hover
              color #FFA134
.task-brand-page
  text-align center
.release-btn
  text-align center
  span
    display inline-block
    width 258px
    height 40px
    line-height 40px
    border 1px solid #FFA134
    color #FFA134
    border-radius 4px
    font-size 16px
    font-weight 400
    margin 0 15px
    margin-top 20px
    cursor pointer
    &.curs
      background #FFA134
      color #fff
.star-tag
  position absolute
  left 0px
  top 5px
  width 20px
  height 27px
  background url('~@/assets/images/workBench/star-label.png') no-repeat
  background-size 75%
  z-index 111
.projectName
  width 100%
  background rgba(255, 161, 52, 0.1)
  color #995F1D
  margin-top 14px
  margin-bottom 12px
  height 40px
  line-height 40px
  font-size 16px
  padding-left 22px
  box-sizing border-box
.task-left, .task-brand-list
  &::-webkit-scrollbar
    width 6px
  &::-webkit-scrollbar-track
    // background-color: #f2f2f2;
    border-radius 10px
  /* 滚动条的滑轨背景颜色 */
  &::-webkit-scrollbar-thumb
    background-color #D9D9D9
    border-radius 10px
  /* 滑块颜色 */
  &::-webkit-scrollbar-button
    // background-color: #000;
  /* 滑轨两头的监听按钮颜色 */
  &::-webkit-scrollbar-corner
    // background-color: black;
  /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
.enterprise-table
  position fixed
  left 0px
  top 0
  width 1400px
  left -1400px
  padding 0px
  background #fff
  text-align center
  z-index -1
  box-sizing border-box
  .edit-head
    padding-top 20px
    padding-bottom 20px
    font-size 24px
    color #000
    font-weight 500
  table
    width 100%
    margin 0 auto
    border-top 1px solid #000
    border-left 1px solid #000
    td
      height 47px
      border-right 1px solid #000
      border-bottom 1px solid #000
      font-size 14px
      text-align center
      color #000
      padding 5px 8px
      color #7B7B7B
.indispensable, dispensable
  display inline-block
.indispensable
  &:before
    content '*'
    color #f00 !important
    margin-right 4px
.dispensable
  display inline-block
  &:before
    content ' '
    margin-right 10px
.task-wrap>>>
  .el-range-input
    color #606266 !important
    font-size 12px
</style>
